<template>
	<div class="IntegralSign">
		<div class="nav mt24 text-c ptb24">
			<div class="mt16 fs16 fw-4 font-33">
				已累计签到<i @click="open" class="iconfont cursor ml8">&#xe600;</i>
			</div>
			<div class="continuous fs16 fw-7 flex mt16">
				<div class="fs28">{{ days }}</div>
				天
			</div>
			<div class="btn mt24">
				<button @click="btn" :style="{ opacity: today ? 0.5 : 1 }">
					点击签到
				</button>
			</div>
		</div>

		<div class="con">
			<div class="list flex justify-between pr00">
				<div class="line"></div>
				<div
					v-for="(item, index) in week_sign_info"
					:key="item.day"
					class="item pr00 flex flex-direction justify-center align-center bgfff"
				>
					<div class="item-con pr00 flex align-center justify-center">
						<img
							class="pa00"
							:src="
								item.is_sign == 1
									? require('@/assets/other/sign-sign-bg.png')
									: require('@/assets/other/sign-not-bg.png')
							"
						/>
						<span class="pr00">+{{ item.integral }}</span>
					</div>
					<span v-if="!today && days == index" class="mt24">今天</span>
					<span v-else-if="today && days - 1 == index" class="mt24">今天</span>
					<span v-else class="mt24">第{{ item.day }}天</span>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "IntegralSign",
	data() {
		return {
			body: "",

			loading: false, // 是否签到中
			week_sign_info: [], // 签到列表
			days: 0, // 连续签到的天数
			today: false, // 今日是否签到过
			score: 0, // 会员积分
		};
	},
	created() {
		this.getData();
	},
	methods: {
		btn() {
			if (this.today) {
				return false;
			}

			if (this.loading) {
				return false;
			}

			this.loading = true;

			this.$API
				.signAjax({
					type: 1,
				})
				.then((res) => {
					if (res.code == 1) {
						this.$message.success("签到成功");
						this.$emit("operate");
						this.getData();
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 获取信息
		getData() {
			this.$API
				.curSign()
				.then((res) => {
					this.loading = false;

					if (res.code == 1) {
						let { week_sign_info, days, today, score } = res.data;

						this.week_sign_info = week_sign_info;
						this.days = days;
						this.today = today;
						this.score = score;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});

			this.$API
				.getAgreement({
					category_id: 20,
				})
				.then((res) => {
					if (res.code == 1) {
						this.body = res.data.content;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		open() {
			if (!this.body) {
				this.$message.info("正在获取规则，请稍后再试");
				return false;
			}

			this.$alert(this.body, "签到规则", {
				type: "none",
				center: true,
				showCancelButton: false,
				showConfirmButton: false,
				dangerouslyUseHTMLString: true,
				closeOnClickModal: true,
			});
		},
	},
};
</script>
<style scoped lang="scss">
.IntegralSign {
	@extend .w100;
	@extend .h100;

	.nav {
		background: url(../assets/imgs/integral-sign-bg.png) no-repeat;
		background-size: 100% 100%;

		.continuous {
			align-items: baseline;
			justify-content: center;
			color: #ff8300;

			div {
				margin-right: 4px;
			}
		}

		.btn {
			button {
				width: 160px;
				height: 40px;
				line-height: 40px;
				background: #ff8300;
				border-radius: 44px;
				font-size: 14px;
				font-weight: 400;
				color: #ffffff;
			}
		}
	}

	.list {
		padding: 0 60px;
		margin-top: 64px;

		.item {
			.item-con {
				width: 38px;
				height: 38px;
				font-size: 12px;
				color: #ffffff;
				border-radius: 50%;
				overflow: hidden;

				img {
					width: 100%;
					height: 100%;
				}
			}

			> span {
				font-size: 16px;
				color: #8f8f8f;
			}
		}

		.line {
			position: absolute;
			top: 20px;
			left: 50%;
			transform: translateX(-50%);
			width: 750px;
			height: 1px;
			background-color: #ececec;
		}
	}
}
</style>
