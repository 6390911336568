<template>
	<div class="Integral bgfff br4 p32">
		<!-- 我的积分 start -->
		<div v-if="curType == 'integration'" class="integration">
			<!-- 标题 start -->
			<div
				class="tit fs18 fw-7 font-33 pr00 pb24 flex justify-between align-center"
			>
				<div class="tit-line flex">
					<span class="pr">我的积分</span>
					<span class="line">我的积分</span>
				</div>
				<!-- <span class="right fw-4 fs14">购买记录</span> -->
			</div>
			<!-- 标题 end -->

			<!-- 积分 start -->
			<div class="nav pr00 mt24 text-c ptb24">
				<div class="score fs28 fw-7">{{ score }}</div>
				<div class="mt16 fs16 fw-4 font-33">
					账户积分<i @click="open" class="iconfont cursor ml8">&#xe600;</i>
				</div>
				<div class="btn mt24">
					<button @click="curType = 'sign'">去签到</button>
					<button
						class="ml16"
						@click="centerDialogVisible = true"
						v-if="userInfo.is_change_score == 0"
					>
						积分兑换
					</button>
				</div>

				<img src="@/assets/imgs/integral-gold.png" />
			</div>
			<!-- 积分 end -->

			<!-- 记录 start -->
			<div class="list-wrap">
				<div class="list-change fs16 font-66 flex mt16">
					<div :class="{ active: type == 0 }" @click="topChange(0)">全部</div>
					<div :class="{ active: type == 1 }" @click="topChange(1)">
						收入记录
					</div>
					<div :class="{ active: type == 2 }" @click="topChange(2)">
						支出记录
					</div>
				</div>

				<div class="list-con">
					<div style="min-height: 600px" v-loading="listLoading">
						<div v-if="arrs.length > 0 || listLoading">
							<div
								v-for="(item, index) in arrs"
								:key="index"
								class="list-item ptb32"
							>
								<div class="flex fs16 font-33">
									<div class="remark">
										{{ item.remark }}
									</div>
									<div
										class="change-score text-r fw-7"
										:style="{
											color: item.change_score > 0 ? '#F03C3B' : '#333333',
										}"
									>
										{{ item.change_score > 0 ? "+" : ""
										}}{{ item.change_score }}
									</div>
								</div>
								<div class="mt16 fs14 font-99">
									{{ item.create_time }}
								</div>
							</div>
						</div>
						<div
							v-else
							class="mt24 bgfff br4"
							style="overflow: hidden; padding-bottom: 60px"
						>
							<Empty></Empty>
						</div>
					</div>

					<!-- 分页 start -->
					<div class="page-wrap flex justify-end mt32">
						<el-pagination
							:background="true"
							layout="prev, pager, next"
							:total="total"
							:current-page="page"
							:page-size="pageSize"
							@current-change="change"
							hide-on-single-page
						>
						</el-pagination>
					</div>
					<!-- 分页 end -->
				</div>
			</div>
			<!-- 记录 end -->
		</div>
		<!-- 我的积分 end -->

		<!-- 签到 start -->
		<div v-if="curType == 'sign'" class="sign">
			<!-- 面包屑 start -->
			<div class="bread flex align-center font-33 pb24">
				您现在的位置：<el-breadcrumb
					separator="/"
					separator-class="el-icon-arrow-right"
				>
					<el-breadcrumb-item
						@click.stop.prevent.native="curType = 'integration'"
						class="is-link"
						>我的积分</el-breadcrumb-item
					>
					<el-breadcrumb-item>签到</el-breadcrumb-item>
				</el-breadcrumb>
			</div>
			<!-- 面包屑 end -->
			<IntegralSign @operate="operate"></IntegralSign>
		</div>
		<!-- 签到 end -->
		<el-dialog title="" :visible.sync="centerDialogVisible" width="30%" center>
			<div class="w100 fs18 text-c font-33 pt32">积分兑换</div>
			<div class="ptb32 plr32">
				<el-input
					v-model="jifen"
					placeholder="请输入要兑换的积分"
					oninput="value=value.replace(/[^\d]/g,'')"
				>
				</el-input>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button
					@click="(centerDialogVisible = false), (jifen = '')"
					class="mb24"
					>取 消</el-button
				>
				<el-button type="primary" @click="shengyujifentixianHandel()"
					>确 定</el-button
				>
			</span>
		</el-dialog>
	</div>
</template>
<script>
import { mapState } from "vuex";
import IntegralSign from "@/components/IntegralSign.vue";
export default {
	name: "Integral",
	components: {
		IntegralSign,
	},
	computed: {
		...mapState({
			userInfo: (state) => state.userInfo,
		}),
	},
	data() {
		return {
			curType: "integration", // 当前在那里 integration 积分; sign 签到;
			score: 0, // 积分
			centerDialogVisible: false,
			body: "", // 规则
			type: 0, // 请求的订单类别
			jifen: "",
			arrs: [],
			total: 0, // 总条数
			pageSize: 15, // 每页条数
			page: 1, // 当前的页码
			listLoading: false,
		};
	},
	created() {
		let { curType = "integration" } = this.$route.params;
		this.curType = curType;
		this.getData();
		this.getList();
	},
	methods: {
		operate() {
			this.page = 1;
			this.type = 0;
			this.getList();
		},
		open() {
			if (!this.body) {
				this.$message.info("正在获取规则，请稍后再试");
				return false;
			}

			this.$alert(this.body, "积分规则", {
				type: "none",
				center: true,
				showCancelButton: false,
				showConfirmButton: false,
				dangerouslyUseHTMLString: true,
				closeOnClickModal: true,
			});
		},

		// 获取信息
		getData() {
			this.$API
				.remainingBalancePoints()
				.then((res) => {
					if (res.code == 1) {
						this.score = res.data.score;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});

			this.$API
				.getAgreement({
					category_id: 123,
				})
				.then((res) => {
					if (res.code == 1) {
						this.body = res.data.content;
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
		// 切换
		topChange(type) {
			if (this.listLoading) {
				return false;
			}

			this.type = type;
			this.page = 1;
			this.getList();
		},
		// 分页改变时
		change(e) {
			this.page = e;
			this.getList();
		},
		//积分兑换余额
		shengyujifentixianHandel() {
			if (!this.jifen) {
				this.$message.info("请输入要提现的积分");
				return false;
			}
			if (Number(this.jifen) > Number(this.score)) {
				this.$message.info("积分不足");
				return false;
			}
			if (Number(this.jifen) < 1000) {
				this.$message.info("每次最少提现1000积分");
				return false;
			}
			this.$API
				.shengyujifentixian({ score: this.jifen })
				.then((res) => {
					if (res.code == 1) {
						this.centerDialogVisible = false;
						this.$message.success("提现成功");
						this.getData();
						this.getList();
						this.jifen = "";
					} else {
						this.$message.info(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},

		// 数据列表
		getList() {
			if (this.listLoading) {
				return false;
			}

			let { page, pageSize, type } = this;

			this.listLoading = true;
			this.$pageScrollTop();

			this.$API
				.integralList({
					page,
					pagesize: pageSize,
					list_rows: pageSize,
					pageSize,
					type,
				})
				.then((res) => {
					this.listLoading = false;
					console.log("res", res);

					if (res.code == 1) {
						this.total = res.data.total;
						this.arrs = res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				})
				.catch((err) => {
					console.log("err", err);
				});
		},
	},
};
</script>
<style scoped lang="scss">
::v-deep {
	.bread {
		.is-link {
			span {
				font-weight: 700;
				text-decoration: none;
				transition: color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
				color: #303133;
				cursor: pointer;

				&:hover {
					color: #409eff;
				}
			}
		}
	}
}

.Integral {
	@extend .w100;
	@extend .h100;

	.integration {
		.tit {
			border-bottom: 1px dashed #eeeeee;

			.right {
				cursor: pointer;
				color: #4177dd;
			}
		}

		.nav {
			background-color: #fff5ea;

			.score {
				color: #ff8300;
			}

			.btn {
				button {
					width: 160px;
					height: 40px;
					line-height: 40px;
					background: #ff8300;
					border-radius: 44px;
					font-size: 14px;
					font-weight: 400;
					color: #ffffff;
				}
			}

			img {
				position: absolute;
				right: 8px;
				top: 22px;
				width: 120px;
				height: 128px;
			}
		}

		.list-wrap {
			.list-change {
				border-bottom: 1px solid #eeeeee;

				div {
					cursor: pointer;
					padding: 24px;
					position: relative;
					left: 0;
					top: 0;

					&:after {
						content: "";
						display: none;
						width: 32px;
						height: 4px;
						background: #ff8300;
						border-radius: 2px;
						position: absolute;
						bottom: 0;
						left: 50%;
						transform: translateX(-50%);
					}
				}

				.active {
					color: #ff8300;
					font-weight: 700;

					&:after {
						display: block;
					}
				}
			}

			.list-con {
				.list-item {
					border-bottom: 1px solid #eeeeee;

					> .flex {
						align-items: baseline;

						.remark {
							flex: 2;
						}

						.change-score {
							flex: 1;
						}
					}
				}
			}
		}
	}
}
</style>
